export enum Product {
  Planning = 'planning',
  Inventory = 'inventory',
  Invoice = 'invoice'
}

export enum Locale {
  EN = 'en',
  SV = 'sv'
}
export type ProductImage = {
  src: string;
  text: string;
}
type ProductSection = {
  title: string;
  description: string;
  keypoints: (string | { text: string; lines: string[] })[];
  img: ProductImage;
  note?: string;
}
type ProductInfo = {
  name: string;
  short: string;
  img: ProductImage;
  description: string;
  sections: ProductSection[];
}
type Products = {
  [Product.Planning]: ProductInfo;
  [Product.Inventory]: ProductInfo;
  [Product.Invoice]: ProductInfo;
}

type Vision = {
  title: string;
  quote: { footer?: string; text: string};
  lines: string[];
}

const common = {
  sv: {
    demo: {
      title: 'Boka en demo',
      description: 'Kontakta oss så bokar vi en en demo av systemet.',
      link: { to: '/contact', text: 'Kontakta Oss' }
    },
    price: {
      title: '149 kr/mån',
      description: 'Vi tar endast betalt en fast avgift per månad',
      note: 'Om driftkostnader överstiger 50kr/mån förbehåller vi oss rätten att debitera detta utöver den fasta avgiften. '
    }
  },
  en: {
    demo: {
      title: 'Book a demo',
      description: 'Contact us and we book a tour of the system.',
      link: { to: '/contact', text: 'Contact us' }
    },
    price: {
      title: '15€ / month',
      description: 'We only change a fixed price per month',
      note: 'If the cost of operating the system exceeds 5€ / month we reserve the right to debit thoes fees as well'
    }
  }
}

export default {
  en: {
    readmore: 'Read more',
    home: {
      title: 'Skooog Tech',
      description: 'We offer products for small companies who want to their digitalization with easy-to-use software.',
      products: 'Our products',
      solution: {
        title: 'Your idea?',
        description: 'We will build a system for you in no time. Simple, fast to a slim price',
        link: { to: '/contact', text: 'Contact Us' }
      },
      vision: 'Our vision',
      visions: [
        {
          title: 'Simple',
          quote: {
            text: 'Simplicity is the ultimate sophistication.',
            footer: 'Leonardo da Vinci'
          },
          lines: [
            'We belive that systems need to be simple and fun to use.',
            'A system that no one want to use doesn\'t fill it\'s purpose.'
          ]
        },
        {
          title: 'Efficient',
          quote: {
            text: 'Solve the problem fast and easy'
          },
          lines: [
            'We belive in simple systems that solves a specifik problem in your organisation',
            'By solving specific problems it is possible to optimize the flow of information and minimize the need for manual processes.'
          ]
        },
        {
          title: 'User friendly',
          quote: {
            text: 'Design isn’t finished until somebody is using it.',
            footer: 'Brenda Laurel, PhD'
          },
          lines: [
            'It is critical that systems are mobile-friendly, accessible and easily navigated',
            'It shold be intuitve to use the system and it should be accessible for everyone in the organisation.'
          ]
        }
      ]
    },
    products: {
      [Product.Planning]: {
        name: 'Skooog Planning',
        short: 'Planning',
        img: { src: 'products/planning-schema', text: 'A smartphone shows the schema of jobs' },
        description: 'Planning is a simple tool to manage a workforce/resource schema digitally',
        sections: [
          {
            title: 'Overview',
            description: 'Planning is a simple tool to manage a workforce/workunit resource schema digitally',
            keypoints: [
              'Create jobs within  different categories',
              'Schedule job onto different resources',
              'Manage job order and size'
            ],
            img: { src: 'products/planning-schema', text: 'A smartphone shows the schema of jobs' }
          },
          {
            title: 'Manage resources',
            description: 'Resources are divided into group, e.g.',
            keypoints: [
              { name: 'Unit group 1', lines: ['Unit 1', 'Unit 2'] },
              { name: 'Unit group 2', lines: ['Unit 3'] }
            ],
            note: 'A unit can be personel, machines, projects or other types of resources that operates on limited tasks',
            img: { src: 'products/planning-jobs', text: 'A smartphone shows three different jobs in a schema' }
          },
          {
            title: 'Manage jobs',
            description: 'As an administrator mange jobs with breeze. Create new jobs, assign them to resources, place them onto the schema and manage size/order',
            keypoints: [
              { name: 'Create', lines: ['Create jobs with name, description, category and unit group'] },
              { name: 'Assign', lines: ['Assign jobs to a resouces within the group'] },
              { name: 'Plan', lines: ['Manage the order and size of the job scheduled for a unit'] }
            ],
            note: 'As a standard user you only see the schema that is updated in realtime when changes are made.',
            img: { src: 'products/planning-job-move.gif', text: 'An animation showing two jobs changing order' }
          },
          {
            ...common.en.demo,
            img: { src: 'products/planning-admin', text: 'Laptop showing a screenshot of the admin panel' }
          },
          common.en.price
        ]
      },
      [Product.Invoice]: {
        name: 'Skooog Invoice',
        short: 'Invoice',
        img: { src: 'products/invoice-reg', text: '' },
        description: 'Manage and confirm invoices',
        sections: [
          {
            title: 'Overview',
            description: 'Manage and confirm invoices',
            keypoints: [
              'Register invoices digitally with image/text',
              'Associate invoice with units and suppliers',
              'Use admin view to search and confirm treated invoices'
            ],
            img: { src: 'products/invoice-reg', text: 'A smartphone shows the invoice form to be filled' }
          },
          {
            title: 'Register invoice',
            description: 'As an user you register invoices with a brezze.',
            keypoints: [
              { name: 'Name / Date' },
              { name: 'Supplier / Unit' },
              { name: 'Add multiple entries a once' },
              { name: 'Attach image and text' }
            ],
            img: { src: 'products/invoice-reg', text: 'A smartphone shows the invoice form to be filled' }
          },
          {
            title: 'Glance invoices',
            description: 'As an admin you can see and confirm invoices',
            keypoints: [
              { name: 'Sort', lines: ['Date', 'Name', 'Unit', 'Supplier'] },
              { name: 'Filtrera', lines: ['Date from/to', 'Supplier', 'Unit'] }
            ],
            img: { src: 'products/invoice-admin', text: 'iPad showing a screenshot of the invoice admin view' }
          },
          {
            ...common.en.demo,
            img: { src: 'products/invoice-admin', text: 'iPad showing a screenshot of the invoice admin view' }
          },
          common.en.price
        ]
      },
      [Product.Inventory]: {
        name: 'Skooog Inventory',
        short: 'Inventory',
        description: 'Manage your inventory easily in your smartphone.',
        img: { src: 'products/inventory-overview', text: '' },
        sections: [
          {
            title: 'Overview',
            description: 'Inventory is a simple tool to manage an inventory.',
            keypoints: [
              'Make withdrawals and inserts',
              'Glance the current stockings',
              'Create a repport over withdrawals'
            ],
            img: { src: 'products/inventory-overview', text: 'A smartphone showing the current invetory stock in a table' }
          },
          {
            title: 'Withdraw',
            description: 'As an user you easily register withdrawals',
            keypoints: [
              'Name / Date',
              'Unit / Article',
              'Customized message for withdrawal of specific articles, e.g. warnings',
              'Dynamic units per article (t.ex. liter / piece / can)'
            ],
            img: { src: 'products/inventory-reg.gif', text: 'An animation shows the withdrawal form being filled.' }
          },
          {
            title: 'Reports',
            description: 'As and administrator you easily overview and register inserts.',
            keypoints: [
              'Overview of withdrawals as a table',
              'Sort / Filter on date'
            ],
            img: { src: 'products/inventory-admin', text: 'Laptop showing a inventory main view with current stocks and last withdrawals' }
          },
          {
            ...common.en.demo,
            img: { src: 'products/inventory-admin', text: 'Laptop showing a inventory main view with current stocks and last withdrawals' }
          },
          common.en.price
        ]
      }
    } as Products,
    contact: {
      title: 'Contact us',
      description: 'The easiest way is to send and email',
      showemail: 'Show email'
    }
  },
  sv: {
    readmore: 'Läs mer om verktyget',
    meta: {
      title: 'Skooog Tech — Verktyg för faktura, lager och planering',
      description: 'Vi erbjuder produkter för mindre företag som vill påbörja sin digitaliseringsresa med enkla och förståeliga verktyg.',
      img: 'https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2'
    },
    home: {
      title: 'Skooog Tech',
      description: 'Vi erbjuder följande produkter för mindre företag som vill påbörja sin digitaliseringsresa med enkla och förståeliga verktyg.',
      products: 'Våra produkter',
      solution: {
        title: 'Din idé?',
        description: 'Vi bygger ett system bara för dig på nolltid. Enkelt och snyggt till ett blygsamt pris.',
        link: { to: '/contact', text: 'Kontakta Oss' }
      },
      vision: 'Våra ledord',
      visions: [
        {
          title: 'Enkelt',
          quote: {
            text: 'Simplicity is the ultimate sophistication.',
            footer: 'Leonardo da Vinci'
          },
          lines: [
            'Vi tror att system behöver vara enkla och roliga att använda för att fylla sitt syfte.',
            'Ett sytem som ingen vill använda fyller inte heller någon funktion.'
          ]
        },
        {
          title: 'Effektivt',
          quote: {
            text: 'Lösa problemet snabbt och enkelt'
          },
          lines: [
            'Vi tror på enkla system som löser ett specifik problem i er organisation.',
            'Genom att lösa specifika problem kan man effektivisera flödet av information och minimera manuell hantering.'
          ]
        },
        {
          title: 'Användarvänligt',
          quote: {
            text: 'Design isn’t finished until somebody is using it.',
            footer: 'Brenda Laurel, PhD'
          },
          lines: [
            'Det är viktigt att systemen är mobilanpassade, tillgängliga och lättnavigerade.',
            'Det ska vara intuitiv att använda verktygen och det ska gå lätt för alla i organisationen.'
          ]
        }
      ] as Vision[]
    },
    products: {
      [Product.Planning]: {
        name: 'Skooog Planering',
        short: 'Planering',
        img: { src: 'products/planning-schema', text: 'En smartphone visar schemavyn' },
        description: 'Plannering är ett enkelt verkytyg för att hantera ett arbetsschema digitalt.',
        sections: [
          {
            title: 'Överblick',
            description: 'Plannering är ett enkelt verkytyg för att hantera ett arbetsschema digitalt.',
            keypoints: [
              'Skapa job med olika kategorier',
              'Placera ut job på olika resurser',
              'Hantera ordning och storlek'
            ],
            img: { src: 'products/planning-schema', text: 'En smartphone visar schemavyn' }
          },
          {
            title: 'Hantera resurser',
            description: 'Resurser delas in i olika grupper. T.ex.',
            keypoints: [
              { name: 'Enhetsgrupp 1', lines: ['Enhet1', 'Enhet2'] },
              { name: 'Enhetsgrupp 2', lines: ['Enhet3'] }
            ],
            note: 'Enheter kan vara personal, maskiner, projekt eller andra typer av resurser som hanterar avgränsade arbetsuppgifter',
            img: { src: 'products/planning-jobs', text: 'En smartphone visar schemavyn med flera job' }
          },
          {
            title: 'Hantera job',
            description: 'Som administrtör hanterar du enkelt jobb. Skapar job, tilldelar job till en resurs, och lägger ut schemat där du hanterar storlek och ordning.',
            keypoints: [
              { name: 'Skapa', lines: ['Skapa job med namn, beskrivning och kategori'] },
              { name: 'Tilldela', lines: ['Tilldela job till en resurs'] },
              { name: 'Planera', lines: ['Styr vilken ordning och storlek av job'] }
            ],
            note: 'Som vanlig användare ser man endast schemat som uppdateras i realtid med ändringar.',
            img: { src: 'products/planning-job-move.gif', text: 'Animering av två jobb som bytar ordning i schemat' }
          },
          {
            ...common.sv.demo,
            img: { src: 'products/planning-admin', text: 'En laptop visar adminpanelen i systemet' }
          },
          common.sv.price
        ]
      },
      [Product.Invoice]: {
        name: 'Skooog Faktura',
        short: 'Faktura',
        img: { src: 'products/invoice-reg', text: 'En smartphone visar fomuläret med en faktura som fylls i' },
        description: 'Hantera och godkänn fakturor',
        sections: [
          {
            title: 'Överblick',
            description: 'Hantera och godkänn fakturor',
            keypoints: [
              'Registrera fakturor digitalt med bild/text',
              'Länka fakturor till enheter och leverantörer',
              'Adminvy för godkända/behandlade fakturor'
            ],
            img: { src: 'products/invoice-reg', text: 'En smartphone visar fomuläret med en faktura som fylls i' }
          },
          {
            title: 'Hantera fakturor',
            description: 'Som admin kan du se och godkänna faktror',
            keypoints: [
              { name: 'Sortera', lines: ['Datum', 'Namn', 'Resultatenhet', 'Leverantör'] },
              { name: 'Filtrera', lines: ['Datum från/till', 'Leverantör', 'Resultatenhet'] }
            ],
            img: { src: 'products/invoice-admin', text: 'En iPad visar adminvyn med registrerade fakturor' }
          },
          {
            title: 'Registrera faktura',
            description: 'Som användare registrierar du enkelt fakutror.',
            keypoints: [
              { name: 'Namn / Datum' },
              { name: 'Leverantör / Enhet' },
              { name: 'Registerar flera fakturaposter samtidigt' },
              { name: 'Bifoga bild eller text' }
            ],
            img: { src: 'products/invoice-reg', text: 'En smartphone visar fomuläret med en faktura som fylls i' }
          },
          {
            ...common.sv.demo,
            img: { src: 'products/invoice-admin', text: 'En iPad visar adminvyn med listan av registrerade fakturor' }
          },
          common.sv.price
        ]
      },
      [Product.Inventory]: {
        name: 'Skooog Lager',
        short: 'Lager',
        description: 'Hantera ditt lager digitalt direkt i mobilen, snabbt och enkelt.',
        img: { src: 'products/inventory-overview', text: 'En smartphone visar det aktuella lagersaldot i en tabell' },
        sections: [
          {
            title: 'Överblick',
            description: 'Lager är ett enkelt verkytyg för att hantera ett lager digitalt.',
            keypoints: [
              'Göra uttag  / insättningar',
              'Överblicka lager saldo',
              'Skapa rapporter över uttag'
            ],
            img: { src: 'products/inventory-overview', text: 'En smartphone visar det aktuella lagersaldot i en tabell' }
          },
          {
            title: 'Uttag',
            description: 'Som användare gör man enkelt uttag ur lagret',
            keypoints: [
              'Namn / Datum',
              'Res.Enhet / Varor',
              'Anpassade meddelande på specfika varor',
              'Dynamiska enheter per vara (t.ex. liter / styck / burk)'
            ],
            img: { src: 'products/inventory-reg.gif', text: 'Animering av hur en vara läggs till i ett lageruttag' }
          },
          {
            title: 'Rapporter',
            description: 'Som administratör skapar du enkelt rapporter och fyller på lagret med varor',
            keypoints: [
              'Överblick av uttag',
              'Filtrera på datum'
            ],
            img: { src: 'products/inventory-admin', text: 'Laptop visar det aktuella lagersaldot och de senaste uttagen' }
          },
          {
            ...common.sv.demo,
            img: { src: 'products/inventory-admin', text: 'Laptop visar det aktuella lagersaldot och de senaste uttagen' }
          },
          common.sv.price
        ]
      }
    } as Products,
    contact: {
      title: 'Kontakta Oss',
      description: 'Gör de enklast genom mejl',
      showemail: 'Visa email'
    }
  }
}
