



























































import { MetaInfo } from 'vue-meta'
import VueI18n from 'vue-i18n'

import messages from '../texts'
import ProductImage from '@/components/ProductImage.vue'

export default {
  name: 'Home',
  components: {
    ProductImage
  },
  metaInfo (): MetaInfo {
    const i18n = (this as unknown as {$i18n: VueI18n}).$i18n
    const title = i18n.t('meta.title') as string
    return {
      title,
      titleTemplate: null as unknown as string // Correct according to docs
    }
  },
  data () {
    return {
      logo: require('../assets/logo.svg'),
      messages
    }
  }
}
