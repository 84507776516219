












import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup () {
    return {
      langs: [{
        locale: 'sv',
        name: 'Svenska'
      }, {
        locale: 'en',
        name: 'English'
      }]
    }
  }
})
