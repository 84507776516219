





























import { defineComponent, PropType } from '@vue/composition-api'
import { ProductImage } from '../texts'

export default defineComponent({
  props: {
    image: {
      type: Object as PropType<ProductImage>,
      required: true
    },
    lazy: {
      type: Boolean,
      default: false
    },
    fluid: {
      type: Boolean,
      default: false
    },
    width: {
      typ: Number,
      default: 175
    }
  },
  setup (props) {
    function hasExtension () {
      return props.image.src.match(/^.+\.\w+$/g)
    }

    function src (size = 175) {
      return hasExtension()
        ? require(`../assets/${props.image.src}`)
        : require(`../assets/${props.image.src}.w${size}.png`)
    }

    const srcset = () => `
        ${src(175)} 1x,
        ${src(350)} 2x,
        ${src(475)} 3x,
        ${src(800)} 4x
      `.replace(/\s+/g, ' ')

    return { src, srcset, hasExtension }
  }
})
