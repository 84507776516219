






































import VueI18n from 'vue-i18n'
import { MetaInfo } from 'vue-meta'
import { SetupContext } from '@vue/composition-api'

import LanguageDropdown from '@/components/LanguageDropdown.vue'
import { provideI18n } from './use/i18n'

function url () {
  const base = process.env.VUE_APP_BASE_URL || document.location.origin
  return base + document.location.pathname
}

export default {
  components: {
    LanguageDropdown
  },
  metaInfo (): MetaInfo {
    const i18n = (this as unknown as {$i18n: VueI18n}).$i18n

    const title = i18n.t('meta.title') as string
    const description = i18n.t('meta.description') as string
    const img = i18n.t('meta.img') as string
    return {
      title: '',
      titleTemplate: '%s - Skooog Tech — Verktyg för faktura, lager och planering',
      meta: [
        { name: 'description', content: description },
        { name: 'theme-color', content: '#349aed' },

        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: url() },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        { property: 'og:image', content: img },

        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:url', content: url() },
        { property: 'twitter:title', content: title },
        { property: 'twitter:description', content: description },
        { property: 'twitter:image', content: img }
      ]
    }
  },
  setup (props: {}, context: SetupContext) {
    provideI18n((context.root as unknown as {$i18n: VueI18n}).$i18n)

    return { logo: require('./assets/logo-dark.svg') }
  }
}
