













import { ref } from '@vue/composition-api'
import { MetaInfo } from 'vue-meta'
import VueI18n from 'vue-i18n'

const a = '\u0062\u0075\u0067\u0067\u0066\u0069\u006c\u006c\u0065'
export default {
  metaInfo (): MetaInfo {
    const i18n = (this as unknown as {$i18n: VueI18n}).$i18n
    return {
      title: i18n.t('contact.title') as string
    }
  },
  setup () {
    const email = ref('')

    const b = `
      \u0040\u0067\u006d\u0061\u0069\u006c\u002e\u0063\u006f\u006d
    `

    function showEmail () {
      setTimeout(() => {
        email.value = '.'
      }, 200)
      setTimeout(() => {
        email.value = '..'
      }, 400)
      setTimeout(() => {
        email.value = '...'
      }, 600)
      setTimeout(() => {
        email.value = a + '+skooog' + b
      }, 800)
    }
    return { showEmail, email }
  }
}
