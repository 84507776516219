

































import { defineComponent, toRefs, computed, PropType } from '@vue/composition-api'
import messages, { Locale, Product } from '../texts'
import ProductImage from '@/components/ProductImage.vue'

export default defineComponent({
  components: {
    ProductImage
  },
  props: {
    product: { type: String as PropType<Product>, required: true },
    locale: { type: String as PropType<Locale>, required: true }
  },
  setup (props) {
    const { product, locale } = toRefs(props)
    return {
      content: computed(() => messages[locale.value].products[product.value])
    }
  }
})
