import { provide, inject } from '@vue/composition-api'
import VueI18n from 'vue-i18n'

const i18nSymbol = Symbol('i18n')

export function provideI18n (i18n: VueI18n) {
  provide(i18nSymbol, i18n)
}

export function useI18n (): VueI18n {
  const i18n = inject(i18nSymbol) as VueI18n
  if (!i18n) throw new Error('No i18n provided!!!')
  return i18n
}
