import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueMeta from 'vue-meta'
import VueCompositionApi from '@vue/composition-api'
import {
  CardPlugin,
  ImagePlugin,
  JumbotronPlugin,
  LayoutPlugin,
  LinkPlugin,
  NavbarPlugin
} from 'bootstrap-vue'

import App from './App.vue'
import router from './router'
import messages from './texts'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

Vue.use(CardPlugin)
Vue.use(ImagePlugin)
Vue.use(JumbotronPlugin)
Vue.use(LayoutPlugin)
Vue.use(LinkPlugin)
Vue.use(NavbarPlugin)

Vue.use(VueCompositionApi)
Vue.use(VueI18n)
Vue.use(VueMeta, { refreshOnceOnNavigation: true })

const i18n = new VueI18n({
  locale: document.location.host.endsWith('.se') ? 'sv' : 'sv',
  fallbackLocale: 'en',
  messages
})

new Vue({
  router,
  i18n,
  render: h => h(App),
  mounted () {
    setTimeout(() => {
      document.dispatchEvent(new Event('x-app-rendered'))
    }, 1000)
  }
}).$mount('#app')
