




import ProductContent from '@/components/ProductContent.vue'
import { MetaInfo } from 'vue-meta'
import { unique } from '../modules/vue-meta-unique'
import VueI18n from 'vue-i18n'

export default ({
  metaInfo (): MetaInfo {
    const p: string = (this as unknown as {product: string}).product
    const i18n = (this as unknown as {$i18n: VueI18n}).$i18n

    const title = i18n.t(`products.${p}.name`) as string
    const description = i18n.t(`products.${p}.description`) as string
    const src = i18n.t(`products.${p}.img.src`)
    const imgalt = i18n.t(`products.${p}.img.text`) as string

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const img = process.env.VUE_APP_BASE_URL + require(`../assets/${src}.w800.png`)

    return {
      title: `${title} - ${description}`,
      titleTemplate: null as unknown as string,
      meta: [
        { name: 'description', content: description },

        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        { property: 'og:image', content: img },

        { property: 'twitter:title', content: title },
        { property: 'twitter:description', content: description },
        { property: 'twitter:image', content: img },
        { property: 'twitter:image:alt', content: imgalt }
      ].map(unique)
    }
  },
  components: {
    ProductContent
  },
  props: {
    product: String
  }
})
